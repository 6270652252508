.whole_content_page .header_content #first_header_content {
  background-image: url("./site_images/Background_header.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
}
.whole_content_page .header_content #first_header_content #first_header_content_left {
  position: relative;
}
.whole_content_page .header_content #first_header_content #first_header_content_left .first_header_content_left_ttc_workspace {
  width: 100%;
  height: auto;
  min-height: 800px;
  min-width: 300px;
}
.whole_content_page .header_content #first_header_content #first_header_content_left .first_header_content_left_ttc_name {
  position: absolute;
  top: 50px;
  left: 40px;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content {
  width: 90%;
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content #first_header_content_right_content_navbar .navbar {
  float: left;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content #first_header_content_right_content_navbar .navbar a {
  text-decoration: none;
  color: #414240;
  font-size: 20px;
  font-weight: 600;
  padding-right: 50px;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content #first_header_content_right_content_navbar .navbar a:last-child {
  padding-right: 0px;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content #first_header_content_right_content_navbar .logo_header {
  float: right;
  margin-top: -40px;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content .first_header_content_right_content_intro {
  margin-top: 100px;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content .first_header_content_right_content_intro h1 {
  font-weight: 900;
  font-size: 5vw;
  line-height: 4.5vw;
  color: #414140;
  word-spacing: 25px;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content .first_header_content_right_content_intro h1 span {
  position: relative;
  color: #1b4484;
  font-size: 8.5vw;
  line-height: 7vw;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content .first_header_content_right_content_intro h1 span span {
  color: #414240;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content .first_header_content_right_content_intro h1 .outline_span {
  font-size: 8.5vw;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 5px;
  color: #ffe02e;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content .first_header_content_right_content_intro h1 .outline_span span {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 5px;
  color: #ffe02e;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content .first_header_content_right_content_intro h1 .cover_outline_span {
  margin-top: 5px;
}
.whole_content_page .header_content #first_header_content #first_header_content_right #first_header_content_right_content p {
  margin-top: 50px;
  width: 90%;
  line-height: 30px;
  font-size: 18px;
  color: #414240;
  line-height: 4 0px;
}
.whole_content_page .customer_service {
  background-color: #f0f0f0;
  margin-top: -100px;
}
.whole_content_page .customer_service .first_customer_service {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  margin-bottom: 400px;
}
.whole_content_page .customer_service .first_customer_service .customer_service_left {
  margin-top: 200px;
}
.whole_content_page .customer_service .first_customer_service .customer_service_left p {
  font-size: 5vw;
  font-weight: 900;
  line-height: 5vw;
  color: #414240;
}
.whole_content_page .customer_service .first_customer_service .customer_service_left p span {
  color: #1b4484;
}
.whole_content_page .customer_service .first_customer_service .customer_service_left p span span {
  color: #414140;
}
.whole_content_page .customer_service .first_customer_service .customer_service_right {
  margin-top: 200px;
}
.whole_content_page .customer_service .first_customer_service .customer_service_right p {
  width: 60%;
  margin: 0 auto;
  font-size: 1vw;
  line-height: 2vw;
  color: #414240;
}
.whole_content_page .customer_service .customer_service_laptop {
  position: absolute;
  bottom: 0px;
  right: 150px;
  max-width: 1000px;
  width: auto;
  height: auto;
}
.whole_content_page .customer_service .customer_service_line {
  width: 100%;
  height: auto;
}
.whole_content_page .current_service {
  width: 80%;
  margin: 0 auto;
}
.whole_content_page .current_service .current_service_content {
  padding: 200px 0px 200px 0px;
  position: relative;
}
.whole_content_page .current_service .current_service_content .service_c_letter {
  height: 150px;
  width: 150px;
  background-color: #ffe02e;
  border-radius: 50%;
  position: absolute;
  left: -40px;
  top: 170px;
}
.whole_content_page .current_service .current_service_content h1 {
  color: #414240;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 900;
  position: relative;
}
.whole_content_page .current_service .current_service_content h1 .service_text {
  color: #1b4484;
}
.whole_content_page .current_service .current_service_content .services_list {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}
.whole_content_page .current_service .current_service_content .services_list .first_service, .whole_content_page .current_service .current_service_content .services_list .second_service {
  position: relative;
  max-width: 450px;
}
.whole_content_page .current_service .current_service_content .services_list .first_service .first_service_top, .whole_content_page .current_service .current_service_content .services_list .second_service .first_service_top {
  margin-bottom: 30px;
}
.whole_content_page .current_service .current_service_content .services_list .first_service .first_service_top .service_circle, .whole_content_page .current_service .current_service_content .services_list .second_service .first_service_top .service_circle {
  height: 200px;
  width: 200px;
  background-color: #ffe02e;
  border-radius: 50%;
  position: absolute;
  top: 55px;
  left: -70px;
}
.whole_content_page .current_service .current_service_content .services_list .first_service .first_service_top img, .whole_content_page .current_service .current_service_content .services_list .second_service .first_service_top img {
  position: relative;
}
.whole_content_page .current_service .current_service_content .services_list .first_service .first_service_top .service_number, .whole_content_page .current_service .current_service_content .services_list .second_service .first_service_top .service_number {
  color: #1b4484;
  font-size: 10em;
  font-weight: 800;
  position: absolute;
  z-index: 1;
  top: 50px;
  left: -100px;
  width: 100px;
}
.whole_content_page .current_service .current_service_content .services_list .first_service h5, .whole_content_page .current_service .current_service_content .services_list .second_service h5 {
  color: #1b4484;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
}
.whole_content_page .current_service .current_service_content .services_list .first_service p, .whole_content_page .current_service .current_service_content .services_list .second_service p {
  color: #414240;
  line-height: 35px;
  font-size: 20px;
}
.whole_content_page .come_work_with_us {
  background-image: url("./site_images/Come_work_with_us_background.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content {
  padding: 200px 0px 0px 0px;
  position: relative;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content .come_work__c_letter {
  height: 150px;
  width: 150px;
  background-color: #ffe02e;
  border-radius: 50%;
  position: absolute;
  left: -40px;
  top: 170px;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content h1 {
  color: #414240;
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 900;
  position: relative;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content h1 .come_work__text {
  color: #1b4484;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content #come_work_bottom {
  margin-top: 130px;
  background-color: #f0f0f0;
  border-radius: 0px 20px 20px 0px;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content #come_work_bottom #come_work_bottom_text {
  padding: 50px;
  font-size: 25px;
  color: #414240;
  line-height: 45px;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content #come_work_bottom #come_work_bottom_text p:first-child {
  font-weight: 600;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content #come_work_bottom #come_work_bottom_text p:last-child {
  font-weight: 500;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content #come_work_bottom #come_work_bottom_text h5 {
  color: #1b4484;
  font-weight: 900;
  font-size: 45px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content #come_work_bottom #come_work_bottom_form {
  padding: 0px;
  box-shadow: rgba(77, 76, 76, 0.35) 10px 5px 15px;
  border-radius: 20px;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content #come_work_bottom #come_work_bottom_form form {
  background-color: white;
  border-radius: 20px;
  padding: 50px;
  font-size: 18px;
  height: 100%;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content #come_work_bottom #come_work_bottom_form form label {
  font-weight: 600;
  color: #636262;
  margin-bottom: 5px;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content #come_work_bottom #come_work_bottom_form form input {
  height: 60px;
  margin-bottom: 40px;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .come_work_content #come_work_bottom #come_work_bottom_form form button {
  margin-top: 50px;
  padding: 10px;
  background-color: #1b4484;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  color: white;
  width: 100%;
}
.whole_content_page .come_work_with_us .come_work_with_us_whole_content .image_bottom {
  width: 75%;
  height: auto;
  position: relative;
  left: -13%;
  margin-top: -15%;
}
.footer {
  background-color: #1b4484;
  color: white;
  margin-bottom: -16px;
}
.footer #footer_content {
  width: 90%;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;
}
.footer #footer_content #footer_content_top {
  --bs-gutter-x: 0px;
}
.footer #footer_content #footer_content_top h6 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
}
.footer #footer_content .trademark {
  padding-left: 0px;
}
.footer #footer_content .trademark p {
  margin-top: 10px;
}
.footer #footer_content .trademark span {
  font-weight: 500;
}
@media only screen and (max-width: 1545px) and (min-width: 0px) {
  .services_list {
    display: flex;
    flex-direction: column !important;
 }
  .current_service {
    display: flex !important;
    justify-content: center !important;
 }
}
@media only screen and (max-width: 1100px) and (min-width: 0px) {
  .first_header_content_right_content_intro {
    width: 100% !important;
 }
  .first_header_content_right_content_intro h1 {
    font-size: 55px !important;
    line-height: 50px !important;
 }
  .first_header_content_right_content_intro h1 span {
    font-size: 90px !important;
 }
}
@media only screen and (max-width: 850px) and (min-width: 0px) {
  #first_header_content_left {
    display: none;
 }
  .first_header_content_right_content_intro {
    margin-bottom: 100px;
 }
  .customer_service_left {
    margin-top: 100px !important;
 }
  .customer_service_right {
    margin-top: 100px !important;
 }
  #first_header_content_right {
    width: 100% !important;
 }
  .customer_service {
    margin-top: 0px !important;
 }
  .cover_outline_span {
    margin-top: 15px !important;
 }
  .current_service .current_service_content h1 {
    font-size: 60px !important;
 }
  .current_service .current_service_content .service_c_letter {
    height: 100px !important;
    width: 100px !important;
    left: -30px !important;
    top: 185px !important;
 }
  .current_service .services_list .first_service .first_service_top .service_number {
    font-size: 75px !important;
    left: -50px !important;
 }
  .current_service .services_list .first_service .first_service_top .service_circle {
    height: 100px !important;
    width: 100px !important;
    top: 50px !important;
    left: -42px !important;
 }
  .current_service .services_list .first_service .first_service_top img {
    max-width: 300px !important;
    width: 100% !important;
    height: auto !important;
 }
  .current_service .services_list .second_service .first_service_top .service_number {
    font-size: 75px !important;
    left: -50px !important;
 }
  .current_service .services_list .second_service .first_service_top .service_circle {
    height: 100px !important;
    width: 100px !important;
    top: 50px !important;
    left: -42px !important;
 }
  .current_service .services_list .second_service .first_service_top img {
    max-width: 300px !important;
    width: 100% !important;
    height: auto !important;
 }
  .current_service .services_list h5 {
    font-size: 20px !important;
 }
  .current_service .services_list p {
    font-size: 13px !important;
 }
  .come_work_content {
    margin-bottom: 100px;
 }
  .come_work_content h1 {
    font-size: 60px !important;
 }
  .come_work_content .come_work__c_letter {
    height: 100px !important;
    width: 100px !important;
    left: -30px !important;
    top: 185px !important;
 }
  .come_work_content #come_work_bottom {
    display: flex;
    flex-direction: column !important;
 }
  .come_work_content #come_work_bottom #come_work_bottom_text {
    font-size: 18px !important;
 }
  .come_work_content #come_work_bottom #come_work_bottom_text h5 {
    font-size: 25px !important;
 }
  .come_work_content #come_work_bottom .form {
    font-size: 18px !important;
 }
  .image_bottom {
    display: none;
 }
}
@media only screen and (max-width: 1177px) and (min-width: 850px) {
  .image_bottom {
    margin-top: 0px !important;
 }
}
@media only screen and (max-width: 440px) and (min-width: 0px) {
  .service_circle {
    display: none;
 }
}
@media only screen and (max-width: 600px) and (min-width: 0px) {
  .navbar {
    display: flex;
    flex-direction: column;
 }
  .logo_header {
    margin-top: 0px !important;
 }
}
@media only screen and (max-width: 1605px) and (min-width: 1470px) {
  .customer_service_laptop {
    max-width: 900px !important;
 }
}
@media only screen and (max-width: 1469px) and (min-width: 1006px) {
  .customer_service_laptop {
    max-width: 750px !important;
 }
}
@media only screen and (max-width: 1200px) and (min-width: 1006px) {
  .customer_service_right p {
    font-size: 15px !important;
 }
}
@media only screen and (max-width: 1005px) and (min-width: 0px) {
  .customer_service_right p {
    font-size: 15px !important;
    line-height: 20px !important;
 }
  .customer_service_laptop {
    max-width: 600px !important;
 }
  .customer_service_left p {
    font-size: 50px !important;
 }
  .customer_service_left p {
    line-height: 40px !important;
 }
  .customer_service_right p {
    margin-top: 100px !important;
 }
}
@media only screen and (max-width: 740px) and (min-width: 0px) {
  .customer_service {
    margin-top: 0px;
 }
  .first_customer_service {
    margin-bottom: 100px !important;
 }
  .customer_service_laptop {
    display: none;
 }
  #first_customer_service_row {
    display: flex;
    flex-direction: column;
 }
  .customer_service_right {
    margin-top: 100px !important;
 }
  .customer_service_right p {
    width: 100% !important;
 }
}
